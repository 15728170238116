import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Guilds from '../Data/Guilds.json';

const LoginButton = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (!code) {
          throw new Error('Authorization code not found');
        }

        setLoading(true);

        // Proceed with token exchange
        const params = new URLSearchParams({
          client_id: '1253137313404293151',
          client_secret: 'U9SK5_rsDQVZdyXR-9LvdL_Fwb930pfA',
          grant_type: 'authorization_code',
          code,
          redirect_uri: 'https://www.kumakult.com/auth/callback',
          // redirect_uri: 'http://localhost:3000/auth/callback',
          scope: 'identify guilds'
        });

        const tokenResponse = await axios.post('https://discord.com/api/oauth2/token', params);
        const accessToken = tokenResponse.data.access_token;

        const userResponse = await axios.get('https://discord.com/api/users/@me', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const guildsResponse = await axios.get('https://discord.com/api/users/@me/guilds', {
          headers: {
              Authorization: `Bearer ${accessToken}`
          }
        });

        const userGuilds = guildsResponse.data;
        const validGuilds = userGuilds.filter(userGuild => Guilds.some(guild => guild.id === userGuild.id));
        const isVerified = validGuilds.length > 0;

        if (!isVerified){
          navigate('/denied');
          return;
        }

        login(userResponse.data, validGuilds);
        navigate('/success');
      } catch (error) {
        console.error('Failed to authenticate with Discord', error);
      } finally {
        setLoading(false);
      }
    };

    handleCallback();
  }, [navigate, login]);

  const handleLogin = () => {
    window.location.href = 'https://discord.com/oauth2/authorize?client_id=1253137313404293151&response_type=code&redirect_uri=https%3A%2F%2Fwww.kumakult.com%2Fauth%2Fcallback&scope=identify+guilds'
    // window.location.href = `https://discord.com/oauth2/authorize?client_id=1253137313404293151&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauth%2Fcallback&scope=identify+guilds`;
  };

  if (loading) {
    return (
      <div className="flex items-center font-shippori">
      <button onClick={handleLogin} className="bg-zinc-500 px-12 py-6 text-white text-lg">
        Authentificating...
      </button>
    </div>
    );
  }

  return (
    <div className="flex items-center font-shippori">
      <button onClick={handleLogin} className="bg-[#5865f2] px-12 py-6 text-white text-lg hover:underline hover:bg-[#727be8] duration-200 transition animate-pulse">Log in via Discord</button>
    </div>
  );
};

export default LoginButton;

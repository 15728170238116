import * as React from 'react';
import BasicCard from './BasicCard';

const UpcomingEvents = ({ events, isAuthenticated }) => {
    return (
        <div className="w-full">
        {events.length > 0 && (
            <div className="w-full mb-12">
                <h2 className="text-black text-2xl mb-6 font-bold">Current Events <span className="px-2 py-1 text-sm text-white bg-cyan-300 rounded-lg">Now</span></h2>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 justify-center">
                {events.map((item) => (
                    <BasicCard key={item.title} item={item} isAuthenticated={isAuthenticated} />
                ))}
                </div>
            </div>
            )}
        </div>
    );
}

export default UpcomingEvents;
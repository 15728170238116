import React from 'react';
import { Grades, Difficulties } from '../Data/constants';

export const getLabelById = (id, list) => {
    for (const key in list) {
        if (list[key].id === id) {
            return list[key];
        }
    }
    return null;
};

export const getClampedAccuracy = (value) => {
    return Math.min(Math.max(parseFloat(value) || 0, 0), 101).toFixed(4);
};

export const getGradeDiv = (acc) => {
    let label = null;
    for (const grade in Grades) {
        if (acc >= Grades[grade].requirement) {
            label = Grades[grade];
        }
    }

    if (label === null) 
        return null;

    return (
        <div className={`text-3xs font-bold ${label.bgColor} text-black px-2 py-0.5 rounded-lg`}>
            {label.label}
        </div>
    );
};

export const getDifficultyBgColor = (id) => {
    let bgColor = 'bg-white';

    switch(id){
        case 2 ** 0: bgColor = 'bg-green-600 text-white'; break;
        case 2 ** 1: bgColor = 'bg-yellow-600 text-white'; break;
        case 2 ** 2: bgColor = 'bg-red-600 text-white'; break;
        case 2 ** 3: bgColor = 'bg-purple-800 text-white'; break;
        case 2 ** 4: bgColor = 'bg-fuchsia-200 text-black'; break;
    }

    return bgColor;
}

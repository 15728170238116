import React from 'react';
import DrinkCard from './../Components/DrinkCard';
import Drinks from './../Data/Drinks.json';
import { FaStar } from "react-icons/fa";

const DrinksPage = () => {
    const sortedDrinks = [...Drinks].sort((a, b) => {
        // Sort by availability (false -> true)
        if (a.available && !b.available) return -1;
        if (!a.available && b.available) return 1;
        // Keep original order if availability is the same
        return 0;
    });
    
  return (
    <main className="py-12 px-4 font-shippori md:flex gap-4 relative w-full">
        <div className="mx-auto w-full md:max-w-7xl">
            <h1 className="text-black text-4xl font-bold">Drinks</h1>
            <h2 className="text-black text-sm mb-6">available during any formal event</h2>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 gap-4 xl:grid-cols-5 w-full">
            {sortedDrinks.map((drink) => (
                <DrinkCard key={drink.name} drink={drink} />
            ))}
            </div>
        </div>
        <div className="sticky -bottom-2 md:bottom-0 md:top-24 right-0 h-32 w-full md:w-auto">
            <div className="bg-white p-4">
                <h2 className="text-lg leading-5">Legend</h2>
                <h2 className="text-xs font-bold mb-2">Customize your drink!</h2>
                <p className="text-xs flex gap-2 text-sm whitespace-nowrap items-center mb-0.5">
                <FaStar className="bg-yellow-300 p-0.5 w-6 h-4 text-center text-black justify-center rounded-full items-center flex gap-1 border-2 border-black"/>
                    Recommended
                </p>
                <p className="text-xs flex gap-2 text-sm whitespace-nowrap items-center">
                    <div className="bg-cyan-300 text-xs w-6 h-3 text-center justify-center rounded-full items-center flex gap-1 border-2 border-black"></div>
                    2x alcohol available
                </p>
                <p className="text-xs flex gap-2 text-sm whitespace-nowrap items-center">
                    <div className="bg-rose-300 text-xs w-6 h-3 text-center justify-center rounded-full items-center flex gap-1 border-2 border-black"></div>
                    0% alcohol available
                </p>
            </div>
        </div>
    </main>
  );
};

export default DrinksPage;

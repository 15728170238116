import React from 'react';
import Events from './../Data/Events.json';
import BasicCard from './../Components/BasicCard';
import UpcomingEvents from './../Components/UpcomingEvents';
import { useAuth } from '../Auth/AuthContext';

const EventsPage = () => {
  const { user, logout } = useAuth();

  const upcomingEvents = Events.filter(event => !event.done);
  const pastEvents = Events.filter(event => event.done);

  return (
    <main className="font-shippori bg-white">
      <div className="max-w-6xl mx-auto py-12 px-4">
        <UpcomingEvents events={upcomingEvents} isAuthenticated={user} />
        <h2 className="text-black text-2xl mb-6 font-bold">History <span className="px-2 py-1 text-sm text-white bg-rose-300 rounded-lg">Past</span></h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {pastEvents.map((item) => (
              <BasicCard key={item.title} item={item} isAuthenticated={user} />
          ))}
        </div>
      </div>
    </main>
  );
};

export default EventsPage;

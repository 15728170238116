import React, { useState, useEffect } from 'react';

const Mascot = () => {
  const [text, setText] = useState('+ - +');

  useEffect(() => {
    const interval = setInterval(() => {
      setText('— - —');

      setTimeout(() => {
        setText('+ - +');
      }, 140);
      
    }, Math.floor(Math.random() * 5000) + 4000);

    return () => clearInterval(interval);
  }, []);

  return (
    <span className="text-center">
        {text}
    </span>
  );
};

export default Mascot;
import React, { useState } from 'react';
import { FaStar } from "react-icons/fa";
import Mascot from "./Mascot";

const DrinkCard = ({ drink }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpansion = () => {
        setExpanded(!expanded);
    };

    return (
        <div className="relative min-h-24 md:min-h-48 w-full cursor-pointer" onClick={toggleExpansion}>
            {!drink.available && 
                <div className="absolute w-full top-16 md:top-32 -translate-y-8 animate-pulse">
                    <h3 className="text-center text-sm font-bold opacity-50"><Mascot/></h3>
                    <p className="text-center text-xs font-bold text-zinc-600 opacity-50">Not available</p>
                </div>
                }
            <div className={`bg-white w-full rounded-t-lg overflow-hidden relative ${expanded && 'border-black border-2 rounded-lg'}  ${!drink.available && 'opacity-20'}`}>
                {drink.image && 
                    <img src={drink.image} className={`w-full h-24 md:h-48 object-cover`} alt={drink.name} />
                }
                { (drink.nonAlcoholicAvailable || drink.doubleAvailable) &&
                    <div className="absolute top-1 right-1 flex flex-col gap-0.5">
                        { drink.doubleAvailable && <div className="shadow-sm bg-cyan-400 text-xs w-8 h-2 text-center justify-center rounded-full items-center flex gap-1 border-2 border-black"></div> }
                        { drink.nonAlcoholicAvailable && <div className="shadow-sm bg-rose-400 text-xs w-8 h-2 text-center justify-center rounded-full items-center flex gap-1 border-2 border-black"></div> }
                    </div>
                }
                { (drink.recommended) &&
                    <div className="absolute top-1 left-1">
                        { drink.recommended && <FaStar className="shadow-sm bg-yellow-300 p-0.5 w-10 h-4 text-center text-black justify-center rounded-full items-center flex gap-1 border-2 border-black"/> }
                    </div>
                }

                <div className="flex flex-col">
                    {(drink.name || drink.abv) && (
                        <div className={`bg-black px-2 py-1.5 flex justify-between items-center`}>
                            {drink.name && <h3 className={`font-bold text-xs whitespace-nowrap w-full ${drink.recommended ? 'text-yellow-300' : 'text-white'}`}>{drink.name}</h3>}
                            {drink.abv && <h3 className={`text-xs whitespace-nowrap ${drink.recommended ? 'text-yellow-500' : 'text-zinc-300'}`}>{drink.abv}</h3>}
                        </div>
                    )}

                    {/* Expanded ingredients */}
                    {expanded && (
                        <ul className="bg-white px-4 py-2">
                            {drink.ingredients.map((item, index) => (
                                <li key={index} className="mb-0.5 text-black text-xs">- {item}</li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DrinkCard;

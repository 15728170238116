import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../Auth/AuthContext';

const DiscordPanel = () => {
  const { user, membership, logout } = useAuth();

  if (!user) {
    return (
    <div className="flex font-shippori h-full">
      <Link className="bg-[#5865f2] hover:bg-[#727be8] transition duration-200 h-full px-8 text-center text-white text-sm hover:underline flex flex-col justify-center whitespace-nowrap" to="/login">Log in</Link>
    </div>
    );
  }

  const avatarUrl = user.avatar
        ? `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`
        : `https://cdn.discordapp.com/embed/avatars/${user.discriminator % 5}.png`;

  let memberText = 'User';

  switch(membership){
    case 1:
      memberText = 'Member';
      break;
    case 2:
      memberText = 'Priority';
      break;
    default:
      memberText = 'User';
      break;
  }

  return (
    <div className="flex items-center font-shippori h-full">
            <div className="rounded-lg text-white text-sm flex items-center h-full max-w-60">
                {/* Profile link with avatar */}
                <Link className="flex h-full sm:px-4 justify-center sm:gap-2 items-center hover:underline text-black bg-yellow-300 hover:bg-yellow-200 transition-color duration-200 w-10 sm:w-32" to="/profile">
                    <img src={avatarUrl} alt="Profile" className="rounded-full w-8 h-8 sm:w-10 sm:h-10 border-black border-2" />
                    {/* Conditional rendering based on screen size */}
                    <div className="text-center items-center flex flex-col sm:hidden"> {/* Hide on xs screens */}
                        {/* Render nothing on xs screens */}
                    </div>
                    <div className="text-center items-center flex flex-col hidden sm:flex"> {/* Show on sm and larger screens */}
                        <p className="font-bold text-black w-full whitespace-nowrap leading-4 text-md">{user.username}</p>
                        <p className="text-black w-full text-xs">
                          { memberText }
                        </p>
                    </div>
                </Link>
                {/* Logout button */}
                <button onClick={logout} className="text-sm hover:underline h-full bg-black px-4">Logout</button>
            </div>
        </div>
  );
};

export default DiscordPanel;
import React, { createContext, useState, useContext, useEffect } from 'react';
import Members from './../Data/Members.json';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')) || null);
  const [guilds, setGuilds] = useState(JSON.parse(localStorage.getItem('guilds')) || null);
  const [membership, setMembership] = useState(JSON.parse(localStorage.getItem('membership')) || 0);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('guilds', JSON.stringify(guilds));
    localStorage.setItem('membership', JSON.stringify(membership));
  }, [user, guilds, membership]);

  const login = (userData, validGuilds) => {
    setUser(userData);
    setGuilds(validGuilds);

    // todo: temp
    const member = Members.find(member => member.Id === userData.id);
    if (member) {
      setMembership(parseInt(member.Status));
    } else {
      setMembership(0);
    }
  };

  const logout = () => {
    setUser(null);
    setGuilds(null);
    setMembership(0);
    localStorage.removeItem('user');
    localStorage.removeItem('guilds');
    localStorage.removeItem('membership');
  };

  return (
    <AuthContext.Provider value={{ user, guilds, membership, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

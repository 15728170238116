import React from 'react';
import { useLocation } from 'react-router-dom';
import Mascot from './Mascot.js';

const Footer = () => {
    const location = useLocation();

    if (location.pathname === "/tools/to")
        return null;

    return (
        <div className="flex justify-between items-center bg-black w-full font-shippori mt-auto">
            <div className="flex px-4 py-2 items-center justify-center w-full h-full">
                <h1 className="text-white text-xs">for more information, please contact 
                    <span className="bg-yellow-300 text-black px-1 font-bold mx-1">wubbo.</span>
                </h1>
                {/* <img src="/kumakult.png" className="w-8 h-8" /> */}
                <div className="w-12 h-12 text-xs font-bold font-sm text-white flex items-center justify-center whitespace-nowrap">
                    <Mascot />
                </div>
            </div>
        </div>
    );
}

export default Footer;
import React, { useEffect, useRef, useState } from 'react';
import SongCard from '../../Components/SongCard';
import BasicCard from '../../Components/BasicCard';
import Charts from '../../Data/Charts.json';
import { useAuth } from '../../Auth/AuthContext';

const FadeUpText = ({ text, className }) => {
    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (!elementRef.current) return;
            const top = elementRef.current.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            if (top < windowHeight * 0.75) {
                setIsVisible(true);
            }
            if (top < -100) {
                setIsVisible(false);
            }
            if (top > windowHeight * 1.25) {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="max-w-lg mx-auto">
            <p
                ref={elementRef}
                className={`text-md text-center my-40 h-screen px-10 mx-auto ${isVisible ? 'opacity-100 translate-y-0 transition-opacity duration-2000 ease-out' : 'opacity-0 translate-y-10'} ${className}`}
            >
                {text}
            </p>
        </div>
    );
};

const JankFestPage = () => {
    const [fadeIn, setFadeIn] = useState(false);
    const [selectedButton, setSelectedButton] = useState(1);
    const buttons = ["Qualifiers", "Round 1", "Round 2", "Round 3", "Top 4+"];
    let filteredCharts = [];
    switch(selectedButton){
        case 0:
            const july27 = new Date(2024, 6, 27, 12);
            const today = new Date();

            if (today >= july27){
                filteredCharts = Charts.filter(song =>  Number(song.Jank) === 5 );
            }
        break;
        case 1:
            filteredCharts = Charts.filter(song =>  Number(song.Jank) === 1 );
        break;
        case 2: 
            filteredCharts = Charts.filter(song =>  Number(song.Jank) === 2 );
        break;
        case 3: 
            filteredCharts = Charts.filter(song =>  Number(song.Jank) === 3 );
        break;
        case 4: 
            filteredCharts = Charts.filter(song =>  Number(song.Jank) === 4 );
        break;
    }

    const sortedCharts = filteredCharts.sort((a, b) => {
        return Number(b.Bud) - Number(a.Bud);
    });

    useEffect(() => { 
        setFadeIn(true);
        window.scrollTo(0, 0);
    }, []);

    return (
        <main className="font-shippori bg-white">
            <div className={`max-w-6xl mx-auto py-16 items-center flex-col flex min-h-[calc(80vh)] transition-opacity duration-5000 ${fadeIn ? 'opacity-100' : 'opacity-0'}`}>
                <div>
                    {/* <h1 className="text-5xl text-center mt-40 mb-4">jank fest 2024</h1> */}
                    <img src="/art/jankfest2024-logo.png" className="lg:max-w-xl mb-2 mx-auto mt-40 mr-4 px-2"/>
                    <h2 className="text-sm text-center font-bold mb-16 text-gray-700 mb-80 animate-bounce">- scroll down -</h2>
                    <div className="h-80"></div>
                    <FadeUpText text="Are you tired of playing tuyu charts?"/>
                    <FadeUpText text="what about the same 13+'s and 14's over and over again?"/>
                    <FadeUpText text="imagine spending hours playing maimai"/>
                    <FadeUpText text="not even trying new charts"/>
                    <FadeUpText text="you suck"/>
                    <FadeUpText text="that's why i created this tournament"/>
                    <FadeUpText text="Jank Fest 2024" className="animate-bounce text-xl font-bold"/>
                    <FadeUpText text="This tournament challenges your fundamentals, dexterity, and mental fortitude."/>
                    <FadeUpText text="You will get to try new charts that you probably WON'T like."/>
                    <FadeUpText text="But who knows, maybe you will find a new favorite!"/>
                    <div className="relative" style={{height:'100rem'}}>
                        <FadeUpText text="Hope to see you soon!" className="sticky top-1/2 absolute h-screen"/>
                    </div>
                </div>
                <div className="px-4 max-w-6xl grid grid-cols-4 gap-4 min-h-[calc(50vh)]">
                    <div className="col-span-4 lg:col-span-1">
                        <div className="w-full bg-black text-white flex items-center px-4 mb-4 h-12">
                            <h3 className="text-2xl">Jank Fest 2024</h3>
                        </div>
                        <p className="text-sm"><strong>Location:</strong> kumakult</p>
                        <p className="text-sm"><strong>Date:</strong> July 27 (Saturday)</p>
                        <p className="text-sm"><strong>Time:</strong> 10:00am - 7:00pm (come early)</p>
                        <p className="text-sm"><strong>Capacity:</strong> 25-30 people. RSVP pls</p>
                        <p className="text-sm mb-4"><strong>Entry:</strong> Everybody welcome! Free entry</p>
                        <button className="sm:text-sm bg-yellow-300 hover:bg-yellow-200 transition duration-200 px-6 py-4 w-full animate-pulse">
                            <a className="w-full h-full" href="https://forms.gle/bZW6sEh8SeqjvJQH9">
                                RSVP NOW
                            </a>
                        </button>
                        <h4 className="text-2xl bg-black text-white px-4 py-1 mb-1 mt-4">Format</h4>
                        <p className="text-sm mb-2"><strong>Qualifiers:</strong> Players are seeded by highest acc out of 3 songs. The songs are pre-determined. Top 16 will proceed.</p>
                        <p className="text-sm"><strong>Round 1:</strong> KO round, 12 move up</p>
                        <p className="text-sm"><strong>Round 2:</strong> KO round, 8 move up</p>
                        <p className="text-sm"><strong>Round 3:</strong> KO round, 4 move up</p>
                        <p className="text-sm"><strong>Top 4:</strong> Best of 5.</p>
                        <p className="text-sm"><strong>Top 2:</strong> Best of 5.</p>
                        <h4 className="text-2xl bg-black text-white px-4 py-1 mb-1 mt-4">Additional Rules</h4>
                        <p className="text-sm mb-2"><strong>KO rounds:</strong> Two players are paired randomly. Three songs are selected - one at a time. Players have 1 song reroll per matchup. <strong>Highest acc in the entire KO pool will move up.</strong></p>
                        <p className="text-sm"><strong>Bo5 rounds:</strong> Players will be seeded by qualifying score. Song selection process will be the same as KO rounds (vote to reroll). <strong>Points are awarded by wins rather than acc</strong> in this stage.</p>

                    </div>
                    <div className="col-span-4 lg:col-span-3 gap-4">
                        <div className="w-full bg-black text-white flex items-center mb-4 h-12 px-4">
                            <h3 className="text-2xl h-8">Map Pool</h3>
                        </div>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 sm:flex-row gap-4 justify-between">
                        {buttons.map((button, index) => (
                            <button
                                key={index}
                                className={`text-xs sm:text-sm transition duration-200 px-6 py-4 w-full ${selectedButton === index ? 'bg-yellow-100 ring-4 ring-black' : 'bg-yellow-300 hover:bg-yellow-200'} ${index===0 ? 'col-span-2 lg:col-span-1' : 'col-span-1'}`}
                                onClick={() => setSelectedButton(index)}
                            >
                                {button}
                            </button>
                        ))}
                        </div>
                        <div className={`border-black my-4 pb-4 gap-2 justify-start
                            ${selectedButton === 0 
                                ? 'grid grid-cols-1 md:grid-cols-3 overflow-hidden'
                                : 'grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 overflow-y-scroll'
                            }`} 
                            style={{height: '45rem'}}
                        >
                            {sortedCharts.map((chart) => (
                                <SongCard key={chart.id} song={chart} />
                            ))}
                            {(selectedButton === 0 && sortedCharts.length === 0) && <BasicCard item={{hidden: true}} />}
                            {(selectedButton === 0 && sortedCharts.length === 0) && <BasicCard item={{hidden: true}} />}
                            {(selectedButton === 0 && sortedCharts.length === 0) && <BasicCard item={{hidden: true}} />}
                            {(selectedButton === 0 && sortedCharts.length === 0) && <p className="flex h-screen text-top text-sm">Qualifiers will be revealed on Jul 27, 11:50am!</p>}
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default JankFestPage;
import React, { useState } from 'react';
import { DxVersion, Categories, GameVersion, Difficulties } from './../Data/constants.js';

const getLabelById = (id, list) => {
    for (const key in list) {
        if (list[key].id === id) {
            return list[key];
        }
    }
    return null;
};

const SongCard = ({ song, small, onClick }) => {
    const [expanded, setExpanded] = useState(false);
    const handleClick = () => {
        if (onClick){
            onClick(song);
        } else {
            setExpanded(!expanded);
        }
    };

    const distribution = song["Distribution"].split('/');
    const spread = [`Notes: ${distribution[0]}`, `Holds: ${distribution[1]}`, `Slides: ${distribution[2]}`, `Touch: ${distribution[3]}`, `Breaks: ${distribution[4]}`, `Combo: ${distribution[5]}`];

    let bgColor = 'bg-black';
    let difficulty = parseFloat(song["Bud"]).toFixed(1);
    switch(song["Difficulty Id"]){
        case 2 ** 0: bgColor = 'bg-green-600 text-white'; break;
        case 2 ** 1: bgColor = 'bg-yellow-600 text-white'; break;
        case 2 ** 2: bgColor = 'bg-red-600 text-white'; break;
        case 2 ** 3: bgColor = 'bg-purple-800 text-white'; break;
        case 2 ** 4: bgColor = 'bg-fuchsia-200 text-black'; break;
        case 2 ** 5: 
            bgColor = 'bg-rose-800 text-white'; 
            difficulty = 'U';
        break;
    }

    return (
        <div className={`relative max-w-60 h-full ${expanded && 'row-span-2'} ${onClick && 'hover:opacity-50'}`} onClick={handleClick}>

            <div className={`bg-white w-full rounded-t-lg overflow-hidden cursor-pointer ${expanded && 'border-black border-2 rounded-lg'}`}>
                {song.Jacket && <img src={
                    `/songs/${song.Jacket}`
                    } className={`w-full ${small ? 'h-24' : 'h-48'} object-cover`} alt={song.Title} />}
                <div className={`${bgColor} px-2 py-1.5 flex justify-between songs-center gap-4`}>
                    {song.Title && <h3 className="font-bold text-xs truncate w-full text-left">{song.Title}</h3>}
                    {song["Bud"] && <h3 className="font-black text-xs">{difficulty}</h3>}
                </div>
                <div className="flex flex-col">
                    {expanded && (
                        <ul className="bg-white px-4 py-2">
                            <li key={song.Id} className="mb-0.5 text-black text-xs"><strong>Title:</strong> {song["Title"]}</li>
                            <li key={song.Id} className="mb-0.5 text-black text-xs"><strong>Artist:</strong> {song["Artist"]}</li>
                            <li key={song.Id} className="mb-0.5 text-black text-xs"><strong>Designer:</strong> {song["Designer"]}</li>
                            <li key={song.Id} className="mb-0.5 text-black text-xs"><strong>Difficulty:</strong> {getLabelById(song["Difficulty Id"], Difficulties).label}</li>
                            <li key={song.Id} className="mb-0.5 text-black text-xs"><strong>BPM:</strong> {song["BPM"]}</li>
                            <li key={song.Id} className="mb-0.5 text-black text-xs mt-2"><strong>Spread</strong>
                                <ul className="grid grid-cols-2">
                                    {spread.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    )}
                </div>
                <div className="absolute top-0 flex flex-wrap w-full mx-1 my-1 gap-1">
                    { song.rerolled && <p className={`whitespace-nowrap text-black text-center item-center lowercase text-xs bg-black px-1.5 rounded bg-rose-500`}>Rerolled</p> }
                    <p className={`whitespace-nowrap text-black text-center item-center lowercase text-xs bg-black px-1.5 rounded ${song["Dx Version"] == 1 ? 'bg-cyan-300' : 'bg-yellow-300'}`}>{getLabelById(song["Dx Version"], DxVersion).short_label}</p>

                    { !small &&
                        <p className="whitespace-nowrap text-white text-center item-center lowercase text-xs bg-black px-1.5 pb-0.5 rounded">{getLabelById(song["Game Version"], GameVersion).label}</p>
                    }
                    { !small &&
                        <p className="whitespace-nowrap text-white text-center item-center lowercase text-xs bg-black px-1.5 pb-0.5 rounded">{getLabelById(song["Category"], Categories).label}</p>
                    }
                </div>
            </div>
        </div>
    );
}

export default SongCard;

import React from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { FaStar, FaGrinStars } from "react-icons/fa";

const today = new Date();

const daysInMonth = (selectedDate) => {
    var date = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
    return date.getDate();
}

const setMonthDelta = (delta, selectedDate, setSelectedDate) => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth();
    const day = selectedDate.getDate();
    const lastDayOfNextMonth = new Date(year, month + delta + 1, 0).getDate();
    const nextMonthDay = Math.min(day, lastDayOfNextMonth);

    var date = new Date(selectedDate);
    date.setMonth(selectedDate.getMonth() + delta);
    date.setDate(nextMonthDay);
    date.setMinutes(0);
    date.setSeconds(0);
    setSelectedDate(date);
};

const getWeekNumber = (day, dayOffset) => {
    return Math.floor((day + dayOffset - 1) / 7);
};

const startingDayOfMonth = (selectedDate) => {
    var date = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
    return date.getDay();
}

const checkBookingOverlap = (booking, selectedDate, day) => {
    const bookingStart = new Date(booking.date);
    const bookingEnd = new Date(booking.date);
    bookingEnd.setHours(bookingEnd.getHours() + booking.duration);

    const selectedStart = new Date(selectedDate);
    selectedStart.setDate(day); 
    selectedStart.setHours(10);
    selectedStart.setMinutes(0);
    selectedStart.setSeconds(0);
    // const selectedEnd = new Date(date);
    // selectedEnd.setHours(index * 2 + 10 + 2);

    return (
        (bookingStart < selectedStart /*selectedEnd*/ && bookingEnd > selectedStart)
    );
};

const DayButton = ({ day, bookings, selectedDate, selectedGame, onClick, dayOffset, user }) => {
    const week = getWeekNumber(day, dayOffset)
    const isToday= day === selectedDate.getDate()
    const isWeek = week === getWeekNumber(selectedDate.getDate())

    const dayBookings = bookings.filter(booking => booking.date.getDate() == day && booking.date.getMonth() == selectedDate.getMonth() && booking.date.getYear() == selectedDate.getYear());
    const isAvailable = bookings.filter(booking => checkBookingOverlap(booking, selectedDate, day) && booking.type === 1).length > 0;
    const isOpenHouse = bookings.filter(booking => checkBookingOverlap(booking, selectedDate, day) && booking.type === 3).length > 0;
    const isEvent = bookings.filter(booking => checkBookingOverlap(booking, selectedDate, day) && booking.type === 4).length > 0;

    const gameBookings = dayBookings.filter(booking => booking.game === selectedGame || (booking.game === 0 && booking.type !== 1));
    const youBookings = dayBookings.filter(booking => booking.id === user.id);

    var bgColor = "";
    var bookableDayStart = new Date(selectedDate);
    bookableDayStart.setDate(day - 1);
    bookableDayStart.setMinutes(0);
    bookableDayStart.setSeconds(0);

    if (isAvailable && bookableDayStart >= today){
        bgColor = "bg-cyan-300";
    } else if (isAvailable){
        bgColor = "bg-green-300";
    }

    if (isOpenHouse) {
        bgColor = "bg-green-400";
    } else if (isEvent){
        bgColor = "bg-purple-400";
    } else if (dayBookings.length > 0) {
        const totalDuration = gameBookings.reduce((sum, obj) => {
            if (obj.duration > 1) {
                return sum + obj.duration;
            } else {
                return sum;
            }
        }, 0);
        
        if (totalDuration >= 8) {
            bgColor = "bg-red-400";
        } else if (totalDuration >= 6) {
            bgColor = "bg-orange-400";
        } else if (totalDuration >= 4) {
            bgColor = "bg-orange-300";
        } else if (totalDuration >= 2) {
            bgColor = "bg-yellow-300";
        }
    }

    return (
    <button
        onClick={onClick}
        className={`flex w-6 h-6 sm:w-8 sm:h-8 text-sm sm:text-md lg:text-lg lg:w-10 lg:h-10 items-center justify-center rounded-full 
            ${isWeek ? 'font-bold text-black' : 'text-zinc-800'}
            ${isToday && 'animate-pulse text-black font-bold text-black ring-4 ring-black'}
            ${bgColor}
            `}>
                { isEvent 
                ? <FaStar /> 
                : youBookings.length > 0 
                    ? <FaGrinStars />
                    : <span>{day}</span>
                }
        </button>
    );
};

const MonthlyCalendar = ({ selectedDate, setSelectedDate, selectedGame, bookings, user }) => {
    const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    const days = Array.from({ length: daysInMonth(selectedDate) }, (_, i) => i + 1);
    const dayOffset = startingDayOfMonth(selectedDate);

    return (
        <div className="bg-white rounded-b-lg overflow-hidden">
            <div className="flex justify-between items-center">
                <div className="flex gap-2 justify-between items-center w-full bg-black px-4 py-1">
                    <button onClick={() => setMonthDelta(-1, selectedDate, setSelectedDate)}>
                        <HiChevronLeft className="h-8 w-8 text-white hover:text-zinc-400 transition-color duration-200" />
                    </button>
                    <h3 className="text-md font-bold text-white">{selectedDate.toLocaleString('default', { year: 'numeric', month: 'long' })}</h3>
                    <button onClick={() => setMonthDelta(1, selectedDate, setSelectedDate)}>
                        <HiChevronRight className="h-8 w-8 text-white hover:text-zinc-400 transition-color duration-200" />
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-7 gap-1 p-4 border-2 border-black rounded-b-lg">
                {daysOfWeek.map((day, index) => (
                    <div key={index} className="text-center text-zinc-400 pb-5">
                        {day}
                    </div>
                ))}
                {Array.from({ length: dayOffset }, (_, i) => (
                    <div key={`offset-${i}`}></div>
                ))}
                {days.map((day, index) => (
                    <DayButton
                        key={index}
                        day={day}
                        dayOffset={dayOffset}
                        bookings={bookings}
                        selectedDate={selectedDate}
                        selectedGame={selectedGame}
                        user={user}
                        onClick={() => {
                            let newDate = new Date(selectedDate);
                            let currentHour = today.getHours();
                            newDate.setDate(day);
                            newDate.setMinutes(0);
                            newDate.setSeconds(0);
                        
                            if (currentHour < 11) {
                                newDate.setHours(12);
                            } else if (currentHour < 13) {
                                newDate.setHours(14);
                            } else if (currentHour < 15) {
                                newDate.setHours(16);
                            } else {
                                newDate.setHours(18);
                            }

                            setSelectedDate(newDate);
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

export default MonthlyCalendar;

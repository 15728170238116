import * as React from 'react';

const PlayerCard = ({ score, player, setPlayer, placeholder, bgColor, previewLocked }) => {

return (
    <div className="flex justify-between gap-1 items-center border-4 border-black bg-white rounded-bl-lg overflow-hidden max-w-96"
    style={{ pointerEvents: previewLocked ? 'none' : 'auto' }}>
        <input
            type="text"
            placeholder={placeholder}
            value={player}
            onChange={(e) => setPlayer(e.target.value)}
            className="px-2 m-1 text-3xl w-full bg-white text-black font-bold caret-transparent focus:outline-none"
        />
        <div className={`font-black text-white h-full text-center items-center justify-center flex ${`${score}`.length <= 2 ? 'px-8 text-5xl' : 'text-2xl px-4'} ${bgColor}`}>
            {score}
        </div>
    </div>

    );
}

export default PlayerCard;

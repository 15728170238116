import React, { useEffect } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { FaGrinStars } from "react-icons/fa";

const today = new Date();
today.setDate(today.getDate())
let bookableDayStart = new Date(today);
bookableDayStart.setDate(today.getDate() + 1);

const getWeekDates = (startDate) => {
    const weekDates = [];
    const current = new Date(startDate);
    current.setDate(current.getDate() - current.getDay());
    for (let i = 0; i < 7; i++) {
        weekDates.push(new Date(current));
        current.setDate(current.getDate() + 1);
    }
    return weekDates;
};

const getNextWeekDate = (date, delta) => {
    const nextWeekDate = new Date(date);
    nextWeekDate.setDate(nextWeekDate.getDate() + delta * 7);
    return nextWeekDate;
};

const setWeekDelta = (delta, selectedDate, setSelectedDate) => {
    const next = getNextWeekDate(selectedDate, delta);
    setSelectedDate(next);
};

const setHour = (date, hour, setSelectedDate) => {
    date.setHours(hour);
    date.setMinutes(0);
    date.setSeconds(0);
    setSelectedDate(date);
};

const isCorrectTime = (time, selectedDate) => {
    const hour = selectedDate.getHours();

    if (time === 12 && hour < 12) {
        return true;
    }

    if (time === hour) {
        return true;
    }

    if (time === 18 && hour > 20) {
        return true;
    }

    return false;
};

const checkBookingOverlap = (booking, date, time) => {
    const bookingStart = new Date(booking.date);
    const bookingEnd = new Date(booking.date);
    bookingEnd.setHours(bookingEnd.getHours() + booking.duration);

    const selectedStart = new Date(date);
    selectedStart.setHours(time);

    return (
        (bookingStart < selectedStart /*selectedEnd*/ && bookingEnd > selectedStart)
    );
};


const HourButton = ({slot, date, selectedDate, setSelectedDate, setIsBookableType, selectedGame, bookings, user}) => {
    var isBookableType = 0;

    const isAvailable = bookings.filter(booking => checkBookingOverlap(booking, date, slot.time) && booking.type === 1).length > 0;
    const hourBookings = bookings.filter(booking => checkBookingOverlap(booking, date, slot.time) && booking.type !== 1);
    const openHouseBookings = bookings.filter(booking => checkBookingOverlap(booking, date, slot.time) && booking.type === 3);
    const eventBookings = bookings.filter(booking => checkBookingOverlap(booking, date, slot.time) && booking.type === 4);
    const gameBookings = hourBookings.filter(booking => booking.game === selectedGame || booking.game === 0);
    const shouldHighlight = isCorrectTime(slot.time, selectedDate) || date.getDate() === selectedDate.getDate();

    // var bookableDayStart = new Date(selectedDate);
    // bookableDayStart.setDate(date.getDate() - 1);
    // bookableDayStart.setMinutes(0);
    // bookableDayStart.setSeconds(0);

    let bgColor = shouldHighlight
        ? 'bg-zinc-100 hover:bg-zinc-200' 
        : 'bg-zinc-50 hover:bg-zinc-100';

    let rounded = "";
    if (slot.time === 12){
        rounded = "rounded-l-lg";
    } else if (slot.time === 18){
        rounded = "rounded-r-lg"
    }

    let localDate = new Date(date);
    localDate.setHours(today.getHours());

    if (isAvailable && bookableDayStart < localDate){
        isBookableType = 1;
        bgColor = shouldHighlight
        ? 'bg-cyan-200 hover:bg-cyan-300' 
        : 'bg-cyan-100 hover:bg-cyan-200';
    } else if (isAvailable){
        isBookableType = 4;
        bgColor = shouldHighlight
        ? 'bg-green-300 hover:bg-green-400' 
        : 'bg-green-300 hover:bg-green-400';
    } else if (hourBookings.length > 0){
        bgColor = 'bg-sky-300 hover:bg-sky-400';
    }

    if (gameBookings.length > 0){
        const userBooked = gameBookings.filter(booking => booking.id === user.id).length > 0
        isBookableType = userBooked ? -1 : 3;
        bgColor = 'bg-red-400 hover:bg-red-500';
    }

    if (openHouseBookings.length > 0){
        isBookableType = 2;
        if (slot.time === 12){
            bgColor = 'bg-green-300 hover:bg-green-400 col-span-4';
            rounded = 'rounded-lg';
        } else {
            return null;
        }
    } else if (eventBookings.length > 0){
        isBookableType = -1;
        if (slot.time === 12){
            bgColor = 'bg-purple-400 hover:bg-purple-500 col-span-4';
            rounded = 'rounded-lg';
        } else {
            return null;
        }
    }

    var isSelected = isCorrectTime(slot.time, selectedDate) && date.getDate() === selectedDate.getDate();
    if (isSelected) {
        setIsBookableType(isBookableType);
    }

    return (
        <button
            onClick={() => {
                setHour(date, slot.time, setSelectedDate);
            }}
            className={`w-full h-full flex items-center justify-center transition-color duration-200 relative flex-col ${bgColor} ${rounded} `}>
            {hourBookings.length >= 1 && (
                <div className="flex flex-col gap-0.5 items-center text-3xs sm:text-2xs text-zinc-700">
                    {hourBookings.map((booking, index) => {
                        if (booking.type >= 2 && booking.notes){
                            return (
                                <span key={index} className="flex gap-1 items-center font-bold">
                                    {booking.notes}
                                </span>
                            );
                        }
                        if (booking.type == 3){
                            return (
                                <span key={index} className="flex gap-1 items-center font-bold">
                                    Open House
                                </span>
                            );
                        }
                        if (booking.id === user.id && booking.game === selectedGame){
                            return (
                                <span key={index} className="flex gap-1 items-center font-bold">
                                    <FaGrinStars /> You
                                </span>
                            );
                        }
                        if (booking.booker && booking.game === selectedGame) {
                            return (
                                <span key={index} className="flex gap-1 items-center">
                                    {booking.booker}
                                </span>
                            );
                        }
                        return null;
                    })}
                </div>            
                )}
            {
                isSelected && <div className="w-full h-full ring-4 ring-black rounded-lg animate-pulse absolute z-10" />
            }
        </button>
    );
}

const WeeklyCalendar = ({ selectedDate, setSelectedDate, setIsBookableType, setIsGameAvailable, bookings, selectedGame, user}) => {
    const weekDates = getWeekDates(selectedDate);
    const timeSlots = [
        {label: '12pm', time: 12, color: "bg-blue-500 text-white"}, 
        {label: '2pm', time: 14, color: "bg-sky-500 text-white"}, 
        {label: '4pm', time: 16, color: "bg-yellow-500 text-white"}, 
        {label: '6-8pm', time: 18, color: "bg-orange-500 text-white"},
    ];

    useEffect(() => {
        const hourBookings = bookings.filter(booking => checkBookingOverlap(booking, selectedDate, selectedDate.getHours()) && (booking.game !== 0 || booking.type === 1));
        const game1Available = !hourBookings.some(booking => booking.game === 1);
        const game2Available = !hourBookings.some(booking => booking.game === 2);
        const game3Available = !hourBookings.some(booking => booking.game === 3);
        let isGameAvailable = [game1Available, game2Available, game3Available];
        
        if (selectedDate < bookableDayStart || hourBookings.length === 0){
            isGameAvailable = [false, false, false];
        }

        setIsGameAvailable(isGameAvailable);
    }, [bookings, selectedDate, setIsGameAvailable]);

    return (
        <div className="bg-white rounded-lg">
            <div className="flex justify-between items-center bg-black px-4 py-2">
                <button onClick={() => setWeekDelta(-1, selectedDate, setSelectedDate)}>
                    <HiChevronLeft className="h-8 w-8 text-white hover:text-zinc-400 transition-color duration-200" />
                </button>
                <h3 className="text-md font-bold text-white">
                    {weekDates[0].toLocaleDateString('default', { month: 'short', day: 'numeric' })} - {weekDates[6].toLocaleDateString('default', { month: 'short', day: 'numeric' })}
                </h3>
                <button onClick={() => setWeekDelta(1, selectedDate, setSelectedDate)}>
                    <HiChevronRight className="h-8 w-8 text-white hover:text-zinc-400 transition-color duration-200" />
                </button>
            </div>
            <div className="grid grid-cols-5 gap-y-0.5 p-1 sm:p-4 border-2 border-black rounded-b-lg">
                <div></div>
                {timeSlots.map((slot, index) => (
                    <div key={index} className="flex items-center justify-center">
                        <div className={`text-xs sm:text-sm whitespace-nowrap text-center text-black mb-1 rounded px-1.5 font-bold py-0.5 mt-1 mb-2 ${slot.color} ${isCorrectTime(slot.time, selectedDate) && 'font-bold'}`}>
                            {slot.label}
                        </div>
                    </div>
                ))}
                {weekDates.map((date, dateIndex) => (
                    <React.Fragment key={dateIndex}>
                        <div className="flex items-center justify-center w-16 h-12">
                            <div className={`text-xs sm:text-sm whitespace-nowrap w-full sm:text-center text-black ${date.getDate() === selectedDate.getDate() && 'font-bold'}`}>
                                {date.toLocaleDateString('default', { weekday: 'short', day: 'numeric' })}
                            </div>
                        </div>
                        {timeSlots.map((slot, index) => (
                            <HourButton 
                                key={index}
                                slot={slot} 
                                date={date} 
                                selectedDate={selectedDate} 
                                setSelectedDate={setSelectedDate} 
                                setIsBookableType={setIsBookableType}
                                selectedGame={selectedGame} 
                                bookings={bookings}
                                user={user} />
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default WeeklyCalendar;

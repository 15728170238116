import React from 'react';
import Events from './../Data/Events.json';
import { useAuth } from './../Auth/AuthContext';
import UpcomingEvents from './../Components/UpcomingEvents';
import Mascot from './../Components/Mascot'

const HomePage = () => {
    const { user, logout } = useAuth();
    const upcomingEvents = Events.filter(event => !event.done);

    return (
        <main className="font-shippori bg-white">
            <div className="max-w-6xl mx-auto py-16 px-4 justify-center items-center flex-col flex min-h-[calc(80vh)]">
                <div>
                    <h1 className="text-5xl text-center">welcome to kumakult</h1>
                    <h2 className="text-xs text-center font-bold mb-16 text-gray-700">too bad. the queue is always busy.</h2>
                    <div className="text-xl font-sm text-black text-center w-full pt-2 mb-20 whitespace-nowrap font-bold animate-bounce">
                        <Mascot/>
                    </div>
                    <p className="text-md text-center mb-32 max-w-lg mx-auto">we are a group of rhythm gamers who like to hang out and run small local events. we offer free tools for you to use!</p>
                </div>
                <UpcomingEvents events={upcomingEvents} isAuthenticated={user} />
            </div>
        </main>
    );
};

export default HomePage;

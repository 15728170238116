import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../Auth/AuthContext';
import { useNavigate } from 'react-router-dom';

const SuccessPage = () => {
    const { user, membership, guilds } = useAuth();
    const navigate = useNavigate();
    const isMember = membership >= 2;

    useEffect(() => {
        if (!user) {
            navigate('/404');
        }
    }, [user, navigate]);

    if (!user) {
        return <h1 className="text-sm flex gap-2">Redirecting...</h1>;
    }

    return (
        <main className="max-w-xl mx-auto py-24 px-4 font-shippori flex flex-col justify-center text-center items-center px-6">
            <h1 className="text-3xl flex gap-2 mb-24">Success!</h1>
            {/* <h2 className="text-sm mb-16 text-gray-500">You may now go to any of the following pages:</h2> */}
            <div className="flex gap-4">
                {isMember &&
                    <Link to="/bookings" className="text-black flex items-center transition-color duration-200 bg-yellow-300 hover:bg-yellow-200 w-48 h-20 justify-center">
                        Bookings
                    </Link>
                }
                {/* Add more links as needed */}
            </div>
            {guilds && guilds.length > 0 && (
                <div>
                    <h2 className="text-xs mt-24 mb-4 text-zinc-700 font-bold">Your guilds</h2>
                    {guilds.map((guild) => (
                        <div className="text-md text-black" key={guild.id}>{guild.name}</div>
                    ))}
                </div>
            )}
            {(!guilds || guilds.length === 0) && (
                <p className="text-xs mt-24 text-zinc-700">No guilds found.</p>
            )}
        </main>
    );
};

export default SuccessPage;

import React from 'react';
import LoginButton from './../Auth/LoginButton';
import BasicCard from './../Components/BasicCard';

const LoginPage = () => {
    const cards = [
        {
            description: "Link your Discord to `Mythos` via maimai db. Track `scores` and `progression`!",
            image: "https://placehold.co/64x64",
            done: true,
        },
        {
            description: "Access `private events` and `more`! Contact `wubbo.` for access if you are a server owner.",
            image: "https://placehold.co/64x64",
            done: true,
        },
        {
            description: "Members can `book` the `kumakult` venue for private use.",
            image: "https://placehold.co/64x64",
            done: true,
        }
    ];
  return (
    <main className="max-w-6xl mx-auto py-12 px-4 font-shippori">
        <h1 className="text-3xl flex gap-2">Login via <span className="bg-[#5865f2] text-lg text-white rounded-lg px-2 py-1 mt-1">Discord</span></h1>
        <h2 className="text-sm mb-16 text-gray-500">Information</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-16">
            {cards.map((item) => (
                <BasicCard key={item.title} item={item} />
            ))}
        </div>

        <div className="flex flex-col w-full justify-center items-center">
            <p className="text-xs mb-4 text-black">Please ensure that you are in an affiliated server before linking your account.</p>
            <LoginButton redirect={false} />
        </div>
    </main>
  );
};

export default LoginPage;
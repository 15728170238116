import React from 'react';

const DeniedPage = () => {
  return (
    <main className="max-w-xl mx-auto py-12 px-4 font-shippori flex flex-col justify-center text-center items-center px-6">
        <h1 className="text-3xl flex gap-2">Access Denied</h1>
        <h2 className="text-sm mb-16 text-gray-500">You are not a member of a whitelisted server.</h2>
        <div className="text-xl font-sm text-black text-center w-full pt-2 mb-20 whitespace-nowrap font-bold animate-bounce">x - x</div>
        <h2 className="text-sm mb-16 text-black">If you are a community admin, please contact wubbo for approval.</h2>
    </main>
  );
};

export default DeniedPage;
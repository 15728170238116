import * as React from 'react';

const BasicCard = ({ item, isAuthenticated }) => {
    const info = item.description?.split('`');
    const highlightColors = ['bg-yellow-300', 'bg-cyan-300', 'bg-rose-400'];

    return (
        <div className={`relative ${item.hidden ? 'h-48': 'h-full min-h-64'} w-full`}>
            <div className={`absolute w-full h-full top-0 left-0 rounded-lg items-center flex gap-1 flex-col justify-center ${!item.done && 'border-2 border-black'} ${((!isAuthenticated && item.locked) || item.hidden) ? 'bg-zinc-200 opacity-100 animate-pulse' : 'bg-white opacity-0'} transition-opacity duration-1000`}>
                <p className="text-zinc-700 text-sm text-center font-bold animate-bounce">x - x</p>
                <p className="text-zinc-400 text-xs text-center font-bold">{item.hidden ? 'Hidden' : 'log in to view content'}</p>
            </div>

            {((isAuthenticated || !item.locked) && !item.hidden) && 
                <div className={`bg-white w-full h-full rounded-lg overflow-hidden ${!item.done && 'border-2 border-black'}`}>
                    {item.image && <img src={item.image} className="w-full h-48 object-cover" alt={item.title} />}
                    <div className="flex flex-col">
                        {(item.title || item.date) && (
                            <div className="bg-black px-2 py-1.5 flex justify-between items-center">
                                {item.title && <h3 className="text-white font-bold text-xs">{item.title}</h3>}
                                {item.date && <h3 className="text-white text-xs">{item.date}</h3>}
                            </div>
                        )}
                        {item.description && (
                            <p className="bg-white px-4 py-2">
                                {info.map((text, index) =>
                                    index % 2 === 0
                                        ? <span key={index} className="text-black"> {text} </span>
                                        : <span key={index} className={`${highlightColors[(Math.floor(index / 2)) % 3]} text-xs font-bold text-black px-1 py-0.5 whitespace-nowrap inline-block`}> {text} </span>
                                )}
                            </p>
                        )}
                    </div>
                </div>
            }
        </div>
    );
}

export default BasicCard;

import React, { useState, useEffect, useRef } from 'react';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';

const TeamViewer = ({ teams }) => {
  const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
  const [animationDirection, setAnimationDirection] = useState('');
  const [key, setKey] = useState(0);

  const handlePrevious = () => {
    setAnimationDirection('slideRight');
    setCurrentTeamIndex((prevIndex) =>
      prevIndex === 0 ? teams.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setAnimationDirection('slideLeft');
    setCurrentTeamIndex((prevIndex) =>
      prevIndex === teams.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    setKey((prevKey) => prevKey + 1); // Change key to force re-render
  }, [currentTeamIndex]);

  const currentTeam = teams[currentTeamIndex];

  return (
    <div className="relative">
      <h4 className="mb-4 font-bold">Example</h4>
      <div className="relative overflow-hidden">
        <div
          key={key}
          className={`flex transition-transform duration-500 ease-in-out ${animationDirection === 'slideLeft' ? 'animate-slideLeft' : ''} ${animationDirection === 'slideRight' ? 'animate-slideRight' : ''}`}
          onAnimationEnd={() => setAnimationDirection('')} // Reset animation direction after animation ends
        >
          {teams.map((team, teamIndex) => (
            <div
              key={teamIndex}
              className={`flex-none w-full ${teamIndex === currentTeamIndex ? 'block' : 'hidden'}`}
            >
              <div className="grid grid-cols-3 gap-2 lg:px-16">
                {team.characters.map((item, index) => {
                  let powerColor = 'bg-yellow-500';
                  switch (item.power) {
                    case 1:
                      powerColor = 'bg-green-500';
                      break;
                    case 2:
                      powerColor = 'bg-yellow-500';
                      break;
                    case 3:
                      powerColor = 'bg-orange-500';
                      break;
                    case 4:
                      powerColor = 'bg-red-500';
                      break;
                    default:
                      powerColor = 'bg-gray-500';
                      break;
                  }

                  return (
                    <div key={index} className="w-full mb-4">
                      <img className="object-cover w-full h-60" src={item.image} alt="Character" />
                      {item.captain && (
                        <span className="bg-cyan-500 text-white font-bold text-sm rounded-lg px-2">
                          Team Captain
                        </span>
                      )}
                      <p>
                        Rating: <span>{item.rating}</span>
                      </p>
                      <p>
                        Power: <span className={`${powerColor} text-white font-bold text-sm rounded-lg px-2`}>{item.power}</span>
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="flex justify-between items-center mt-4 w-full">
                <div>
                  Team Total:{' '}
                  <span className="bg-rose-500 text-white font-bold text-sm rounded-lg px-2">
                    {team.total} points
                  </span>
                </div>
                {team.handicap && (
                  <div>
                    Handicap:{' '}
                    <span className="bg-cyan-500 text-white font-bold text-sm rounded-lg px-2">
                      +1 Ability
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={handlePrevious}
          className="absolute transform -translate-y-1/2 top-4 lg:top-1/2 left-0 carousel-button left"
        >
          <FaArrowAltCircleLeft size={32} />
        </button>
        <button
          onClick={handleNext}
          className="absolute transform -translate-y-1/2 top-4 lg:top-1/2 right-0 carousel-button right"
        >
          <FaArrowAltCircleRight size={32} />
        </button>
      </div>
    </div>
  );
};

export default TeamViewer;
